body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.show-field {
  width: 350px;
}

.show-field table th span {
  font-size: 12px;
  font-weight: 900;
}

.show-field table td span {
  font-size: 16px;
  font-weight: 200;  
}

.show-field span {
  font-size: 20px;
  font-weight: 200;
}

.show-field label span {
  font-size: 16px;
  font-weight: 900;
}

.show-field table td a {
  font-size: 16px;
  font-weight: 200;
}

.show-field a {
  font-size: 20px;
  font-weight: 200;
}

.show-field label a {
  font-size: 16px;
  font-weight: 900;
}

.show-field-bold {
  width: 350px;
}

.show-field-bold  label span {
  font-size: 16px;
  font-weight: 900;
}

.show-field-bold span {
  font-size: 20px;
  font-weight: "bold";
}

/* DialogTitle Component */
.dialog-title {
  text-align: center;
}

.dialog-actions {
  justify-content: center;
}

.cancel-button {
  background-color: red;
  color: white;
}

.order-actions button {
  margin-right: 10px;
  margin-left: 10px;
}